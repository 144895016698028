
const state = () => ({
  appStoreLink: 'https://apps.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%B8-%D1%81%D0%B5%D1%82/id1468185600?l=ru',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=group.mb.sushiset&pcampaignid=web_share',
  ruStoreLink: 'https://www.rustore.ru/catalog/app/group.mb.sushiset',
})

export default {
  namespaced: true,
  state,
}
