<template>
  <div class="mobile-apps_item">
    <a v-if="allowDirectDownloadMobileApp || alwaysSowLinks" :href="getUrl" target="_blank">
      <img v-if="platform === 'apple'" src="@/assets/images/appstore.svg" alt="">
      <img v-if="platform === 'gplay'" src="@/assets/images/gplay.svg" alt="">
      <img v-if="platform === 'rustore'" src="@/assets/images/ru-store.svg" alt="">
    </a>

    <div v-else>
      <img v-if="platform === 'apple'" src="@/assets/images/appstore.svg" alt="">
      <img v-if="platform === 'gplay'" src="@/assets/images/gplay.svg" alt="">
      <img v-if="platform === 'rustore'" src="@/assets/images/ru-store.svg" alt="">
    </div>
  </div>
</template>

<script>
import UserDeviceDetect from '~/mixins/user-device-detect'

export default {
  mixins: [UserDeviceDetect],
  props: {
    platform: {
      type: String,
      default: 'apple',
    },
    alwaysSowLinks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

    getUrl () {
      switch (this.platform) {
        case 'apple': return this.$store.state.mobileApp.appStoreLink
        case 'gplay': return this.$store.state.mobileApp.googlePlayLink
        case 'rustore': return this.$store.state.mobileApp.ruStoreLink
        default: return this.$store.state.mobileApp.googlePlayLink
      }
    },
  },
}
</script>

<style lang="less">
.mobile-apps_item {
  display: block;

  img {
    width: auto;
    height: 39px;

    @media screen and (max-width: 1200px) {
      height: 35px;
    }
  }
}
</style>
